import React, { Fragment } from "react";
import VideoModal from "../components/VideoModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";

import ep1 from "../images/ep1.jpg";
import ep2 from "../images/ep2.jpg";
import ep3 from "../images/ep3.jpg";
import ep4 from "../images/ep4.jpg";

export default function Videos() {
  return (
    <Fragment>
      <h1 style={{ textAlign: "center" }}>Watch our videos</h1>
      <Container>
        <Row>
          <Col sm="4">
            <Card style={{ margin: "1%" }}>
              <CardImg top width="100%" src={ep1} alt="Card image cap" />
              <CardBody>
                <CardTitle>Tale I - It Has Begun</CardTitle>
                <CardSubtitle>Jul 22, 2019</CardSubtitle>
                <CardText>
                  Elephant get hooked on Rat's tasty soup and keeps going back
                </CardText>
                <VideoModal videoUrl="https://www.youtube.com/embed/xg7RHp5j00s?controls=1" />
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card style={{ margin: "1%" }}>
              <CardImg top width="100%" src={ep2} alt="Card image cap" />
              <CardBody>
                <CardTitle>Tale II - The Night Burglar</CardTitle>
                <CardSubtitle>Jul 22, 2019</CardSubtitle>
                <CardText>
                  Elephant sneaks into Rat's house at night for soup
                </CardText>
                <VideoModal videoUrl="https://www.youtube.com/embed/L1hiCthwyFc?controls=1" />
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card style={{ margin: "1%" }}>
              <CardImg top width="100%" src={ep3} alt="Card image cap" />
              <CardBody>
                <CardTitle>Tale III - Wrong Neighborhood</CardTitle>
                <CardSubtitle>Jul 22, 2019</CardSubtitle>
                <CardText>Rat finds himself in thw wrong neighborhood</CardText>
                <VideoModal videoUrl="https://www.youtube.com/embed/INyXsgS-gqw?controls=1" />
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card style={{ margin: "1%" }}>
              <CardImg top width="100%" src={ep4} alt="Card image cap" />
              <CardBody>
                <CardTitle>Tale IV - Got The Thyme?</CardTitle>
                <CardSubtitle>Aug 3, 2019</CardSubtitle>
                <CardText>
                  Elephant is running out of thyme (time). Come watch!
                </CardText>
                <VideoModal videoUrl="https://www.youtube.com/embed/_jnYH61v8lc?controls=1" />
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card style={{ margin: "1%" }}>
              <CardImg top width="100%" src={ep4} alt="Card image cap" />
              <CardBody>
                <CardTitle>Tale V - The Loan</CardTitle>
                <CardSubtitle>Aug 30, 2019</CardSubtitle>
                <CardText>
                  After meeting with old friends, Rat realizes he wants his own
                  restaurant
                </CardText>
                <VideoModal videoUrl="https://www.youtube.com/embed/ALph0DNvKzI?controls=1" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
