import React from "react";
import Footer from "../components/Footer";
import { Badge } from "reactstrap";
import superman from "../images/superman.jpg";
import Bounce from "react-reveal/Bounce";

export default function Donate() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Hey, donate here so i can eat more!</h1>

      <Bounce right>
        <img src={superman} width="50%" alt="donate" />
      </Bounce>

      <h3>
        bitcoin:{" "}
        <Badge
          color="success"
          pill
          href="bitcoin:bc1qpv33ak3xwnczpkhmh077cyjypx2myn580gjhq6"
        >
          bc1qpv33ak3xwnczpkhmh077cyjypx2myn580gjhq6
        </Badge>
      </h3>

      <Footer />
    </div>
  );
}
