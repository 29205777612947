import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function VideoModal(props) {
  const [state, setState] = useState({
    modal: false
  });

  const toggle = () => {
    setState({
      modal: !state.modal
    });
  };

  return (
    <div>
      <Button style={{ width: "100%" }} color="danger" onClick={toggle}>
        Watch Now
      </Button>
      <Modal size="lg" isOpen={state.modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <iframe
            title="R & E video"
            src={props.videoUrl}
            width="100%"
            height="400"
          ></iframe>
        </ModalBody>
        <ModalFooter>
          <h1>Enjoy!</h1>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default VideoModal;
