import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

export default function Navigationbar() {
  const [state, setState] = useState({
    collapsed: true
  });

  const toggleNavbar = () => {
    setState({
      collapsed: !state.collapsed
    });
  };

  const closeToggle = () => {
    if (!state.collapsed) {
      setState({
        collapsed: true
      });
    }
  };

  return (
    <div>
      <Navbar color="faded" light style={{ backgroundColor: "ghostwhite" }}>
        <NavbarBrand
          className="mr-auto"
          tag={Link}
          to="/"
          onClick={closeToggle}
        >
          R & E
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!state.collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink tag={Link} to="/videos" onClick={closeToggle}>
                Videos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/apparel" onClick={closeToggle}>
                Apparel
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/donate" onClick={closeToggle}>
                Donate
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}
