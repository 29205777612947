import React from "react";

export default function Footer() {
  return (
    <h5
      style={{
        textAlign: "center",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: ".5%"
      }}
    >
      © 2019 Rat and Elephant
    </h5>
  );
}
