import React from "react";
import "./App.css";
import homepageImg from "../images/homepage.jpg";
import Fade from "react-reveal/Bounce";
import Footer from "../components/Footer";

function App() {
  return (
    <div style={{ textAlign: "center" }}>
      <Fade top>
        <h1>Hey, welcome to our site</h1>
        <img width="50%" src={homepageImg} alt="Home page" />
      </Fade>
      <Footer />
    </div>
  );
}

export default App;
