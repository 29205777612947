import React from "react";
import Footer from "../components/Footer";

export default function error() {
  return (
    <div>
      404 page not found...
      <Footer />
    </div>
  );
}
