import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./pages/Home";
import Videos from "./pages/Videos";
import Apparel from "./pages/Apparel";
import Donate from "./pages/Donate";
import Error from "./pages/Error";
import * as serviceWorker from "./serviceWorker";
import Navbar from "./components/Navigationbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "typeface-roboto";

const tmp = (
  <Router>
    <Navbar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/videos" component={Videos} />
      <Route exact path="/apparel" component={Apparel} />
      <Route exact path="/donate" component={Donate} />
      <Route component={Error} />
    </Switch>
  </Router>
);

ReactDOM.render(tmp, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
