import React from "react";
import Footer from "../components/Footer";
import rob from "../images/rob.jpg";
import Bounce from "react-reveal/Bounce";

export default function Apparel() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>apparel coming soon</h1>

      <Bounce top>
        <img src={rob} width="50%" alt="apparel" />
      </Bounce>

      <Footer />
    </div>
  );
}
